.navbar-dark .navbar-dropdown::after {
  color: var(--bs-navbar-active-color);
}
.bi {
  line-height: 0.9;
  display: inline-block;
  vertical-align: -.125em;
  fill: currentcolor;
}
.table-dropdown {
  line-height: 1;
  width: 32px;
  height: 32px;
}
.dropdown .hide-drop-icon::after {
  display: none;
}
.fs-7 {
  font-size: .9rem;
}