.reset-password-form-container{
  display: flex;
  height: 100vh;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}
.reset-password-form-container .reset-password-signin {
  width: 100%;
  max-width: 400px;
  margin: auto;
}
.reset-password-form-container .reset-password-signin input[name="old_password"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.reset-password-form-container .reset-password-signin input[name="password"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.reset-password-form-container .reset-password-signin input[name="confirm_password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}