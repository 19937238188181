.workinfo-checkbox-container {
  display: inline-block;
  position: relative;
}

.checkbox-custom {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  position: absolute;
  top: 0;
  left: 0;
}

.checkbox-number {
  font-size: .75rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.workinfo-checkbox-container input[type="checkbox"] {
  display: none;
}

.workinfo-checkbox-container input[type="checkbox"]:checked + .checkbox-custom,
.workinfo-checkbox-container .checkbox-custom.checked {
  background-color: #88c399;
}